
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { AuthService } from '../services/auth-service';
import { State, Action, Getter } from 'vuex-class';
import { ProfileState, User } from 'src/store/models/ProfileModel';
import BasicHelpDialog from '@/components/BasicHelpDialog.vue';
import { AxiosError } from 'axios';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { CookieHelpers } from '@/helpers/CookieHelper';

const namespace: string = 'profile';

@Component({
  components: { BasicHelpDialog }
})
export default class LoginComponent extends Vue {
  $refs!: {
    helpDeskDialog: HTMLFormElement;
  };
  @Getter('isAuthenticated', { namespace })
  isAuthenticated!: boolean;
  @Action('login', { namespace })
  login: any;
  @Action('GetItemInfo', { namespace: 'items' })
  getItemInfo: any;
  @Action('getDieTemplates', { namespace: 'dies' })
  getDieTemplates: any;
  @Action('getCurrentCustomer', { namespace: 'customer' })
  getCurrentCustomerStore: any;
  UserName: string = '';
  Password: string = '';
  hasLoginError: boolean = false;
  errorMsg: string =
    'Invalid Username or Password. Please contact techsupport@just1label.com if you are experiencing any issues';
  userNameAutofilled: boolean = false;
  passwordAutofilled: boolean = false;
  shouldShowPassword: boolean = false;
  didAcceptTerms: boolean = false;
  canLogin: boolean = false;
  isConfirmed: boolean = false;

  EmailRules: any[] = [
    (v: any) => !!v || 'E-mail is required',
    (v: any) => /.+@.+\..+/.test(v) || v == 'admin' || 'E-mail must be valid'
  ];
  isLoggingIn: boolean = false;
  metaInfo(): any {
    return {
      meta: [
        {
          name: 'description',
          content:
            'Sign in to the Just 1 Label Portal. Just 1 Label is a leading provider of pressure-sensitive custom labels.'
        }
      ],
      title: 'Just 1 Label Login | Sign in to the Just 1 Label Portal'
    };
  }
  goToSignUp(): void {
    this.$router.push('/Register');
  }
  goTo(path: string): void {
    this.$router.push(path);
  }
  goToAmazonTransparency() {
    window.location.href = 'https://www.just1label.com/transparency';
  }
  openHelpDesk() {
    this.$refs.helpDeskDialog.openDialog();
  }
  goToPrivacy() {
    this.$router.push({
      name: 'Privacy'
    });
  }
  loginUser() {
    if (!this.canLogin || !this.didAcceptTerms) return;
    this.isLoggingIn = true;
    this.hasLoginError = false;
    const formData: any = new FormData();
    formData.set('userName', this.UserName);
    formData.set('Password', this.Password);
    this.login(formData)
      .then(() => {
        this.isLoggingIn = false;
        this.getDieTemplates();
        this.getCurrentCustomerStore();
        this.getItemInfo();
        this.$router.push('/items').catch();
        // let cookieName = 'home.just1label.comNotificationDismissed';
        // if (
        //   window.location.hostname ==
        //     `${process.env.VUE_APP_BASE_URL}`.replace(/^[a-zA-Z]+:\/\//, '') &&
        //   !CookieHelpers.getCookie(cookieName)
        // ) {
        //   SetSnackBar(
        //     'Site Update : Please note as of 02/09/24 the logged in version of our site (the site you are visiting right now) has changed from <strong>www.just1label.com</strong> to <strong>home.just1label.com</strong>. If you have a bookmark saved in your browser we recommend deleting it and saving a new bookmark from this page.',
        //     'DISMISS',
        //     () => {
        //       let expirationDate = new Date();
        //       expirationDate.setFullYear(expirationDate.getFullYear() + 10);
        //       CookieHelpers.setGenericCookie(cookieName, expirationDate);
        //     },
        //     -1,
        //     'primary',
        //     'white'
        //   );
        // }
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400) {
          this.errorMsg = err.response.data.message;
        }
        this.hasLoginError = true;
        this.isLoggingIn = false;
      });
  }
  @Watch('isAuthenticated')
  onPropertyChanged(areThey: boolean, oldValue: boolean) {
    if (areThey) {
      if (this.$route.params.nextUrl && this.$route.params.nextUrl !== '/items') {
        this.$router.push(this.$route.params.nextUrl);
      } else {
        this.$router.push('/items').catch(err => {});
      }
    }
  }
  @Watch('UserName')
  onUserNameAutoFill(val: string) {
    if (val) this.userNameAutofilled = true;
    else this.userNameAutofilled = false;
  }
  @Watch('Password')
  onUPasswordAutoFill(val: string) {
    if (val) this.passwordAutofilled = true;
    else this.passwordAutofilled = false;
  }
  mounted() {
    if (this.$route.params.confirmed) this.isConfirmed = true;
  }
}
